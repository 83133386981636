<template>
    <div class="home-box">
        <el-page-header @back="goBack" :content="pageTitle" style="margin-bottom:20px">
        </el-page-header>
        
      <div>
        <div class="el-dialog-header-box">
          <div class="dialog-title-block"></div>
          <span class="dialog-title-text">{{userInfo.title}} - 金额明细</span>
        </div>
        <el-divider />
      </div>

      <!-- 查询区域 -->

      <!-- table区域-begin -->
      <div class="table-box">
        <el-table :data="tableData.data" stripe style="width: 100%" v-loading="loading">
          <el-table-column prop="id" label="订单ID" width="120">
          </el-table-column>
          <el-table-column prop="balance" label="变更余额" min-width="120">
            <template slot-scope="scope">
              <span>{{ scope.row.balance }}</span><br>
              <span>订单ID：{{ scope.row.orderid }}</span><br>
              <span>订单号：{{ scope.row.order_id }}</span><br>
            </template>
          </el-table-column>
          <el-table-column prop="old_balance" label="变更前余额" min-width="120">
          </el-table-column>
          <el-table-column prop="status" label="状态" min-width="120">
            <template slot-scope="scope">
              <el-tag :type="scope.row.status == 1 ? '' : 'danger'">{{ statusObject[scope.row.status] }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="remark" label="备注" min-width="120">
          </el-table-column>
          <el-table-column prop="type" label="类型" min-width="120">
            <template slot-scope="scope">
              <el-tag :type="
                scope.row.type == 1 ? '' : 
                scope.row.type == 2 ? 'success' : 
                scope.row.type == 98 ? 'info' : 
                scope.row.type == 99 ? 'warning' : 
                'danger'">
                {{ typeObject[scope.row.type] }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="createdate" label="创建时间" min-width="120">
          </el-table-column>
        </el-table>
      </div>
      <!-- table区域-end -->
  
      <!-- 分页区域-begin -->
      <div>
        <el-pagination
          class="pagination"
          background layout="prev, pager, next"
          :total="tableData.total"
          :page-size="tableData.per_page"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange">
        </el-pagination>
      </div>
      <!-- 分页区域-end -->
    </div>
  </template>
  
  <script>
  import Vue from "vue";
  import { mapState, mapMutations } from "vuex";
  import axios from "axios";
  
  export default Vue.extend({
    name: "paidOrders",
    data() {
      return {
        pageTitle: '',
        token: '',
        userInfo: {},
        tableData: {},
        statusObject: {},
        typeObject: [],
        loading: false,
        current_page: 1,
      };
    },
    computed: {
      ...mapState(["user", "setting"]),
    },
    mounted() {
      let user = JSON.parse(localStorage.user);
      this.token = user.token;
      this.getBalanceDetail()
    },
    methods: {
      ...mapMutations(["setuser", "setsetting"]),
      handleSizeChange(val) {
        console.log(`每页 ${val} 条`);
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`);
        this.current_page = val
        this.getBalanceDetail()
      },
      goBack() {
        console.log('go back');
        this.$router.back()
      },
      // 获取余额明细
      getBalanceDetail(id) {
        this.loading = true
        let page = this.current_page 
        this.$api.balanceDetail({id, page}).then(
          res => {
            if(res.code == 'success'){
              this.tableData = res.data.list
              this.userInfo = res.data.info
              this.statusObject = res.data.status
              this.typeObject = res.data.type
            }else{
              this.$message.error(res.msg)
            }
            this.loading = false
          }
        ).catch(err=>{
          this.$message.error(err.msg)
          this.loading = false
        })
      },
    },
  });
  </script>
  
  <style scoped lang="less">
  @import "../../assets/less/easyElement.less";
  </style>
  